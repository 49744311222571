import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to make a podcast media kit?"
          description="Use our templates and sample to create the best podcast media kit, to show off your podcast to the press"
        />

        <article>
          <h1>How to make a podcast media kit?</h1>

          <p>
            <strong>A press media kit will help you sell your podcast to Journalists & sponsors.</strong>
          </p>

          <p>A podcast press kit if your elevator pitch to convince people to listen.</p>

          <p>It’s in your benefit to make it easy for them to find and share the best information for your podcast.</p>

          <p>
            If you are on the fence whether to create a media kit or not. You should give it a try. A podcast media kit
            can help to:
          </p>
          <ul>
            <li>Find your perfect audience</li>
            <li>Make it easy for editors to write about your podcast</li>
            <li>Pitch your podcast to stand out from the crowd.</li>
          </ul>
          <p>
            <strong>What should a press kit include?</strong>
          </p>
          <p>
            To build a valuable pack, its important to include the right information in a simple to understand format.
            Follow this template in your kit;
          </p>
          <ol>
            <li>Short Introduction pitch your podcast</li>

            <li>A short overview of podcast history & creators.</li>

            <li>Facts List (updated every quarter)</li>

            <li>Podcast Art (Cover Art, Logos & Colours)</li>

            <li>Podcast Video Trailer & Clips</li>

            <li>Links to iTunes, Website, Google Play</li>
          </ol>

          <section>
            <GetStartedOrChat cta="Generate podcast clips for your Media Kit with EchoWave" />
            <br />
          </section>

          <p>
            <strong>What Facts to include in your podcast kit?</strong>
          </p>
          <p>You should put any information that is relevant to your podcast, such data includes;</p>
          <ul>
            <li>Release schedule.</li>

            <li>Download Numbers / Listener & RSS stats</li>

            <li>Podcast Category</li>

            <li>Total episodes count</li>

            <li>Milestones & Timeline</li>

            <li>Notable Quotes from media</li>

            <li>iTunes Rating</li>

            <li>Key Guests / Cast, Hosts & Crew</li>

            <li>Contact Information: Email Address</li>

            <li>Last Updated Date</li>
          </ul>
          <p>
            <strong>What Format should the press kit be?</strong>
          </p>
          <p>
            You want to make it easy to share your podcast kit, as well as ensure it renders currently. We recommend
            either hosting the kit on your website or a zip folder with the assets and a PDF.
          </p>
          <p>
            <strong>Best Media Kit Designs</strong>
          </p>
          <p>
            The best-designed kits are simple and show off your podcast art and data in its best light. Put a focus on
            facts that make your podcast stand out. For example if you are on the 6000th episode you should highlight
            this!
          </p>
          <p>
            <strong>How To make a media kit?</strong>
          </p>
          <p>
            You do not need special tools to make the kit, you can add a new interactive page to your website, or create
            a PDF using tools like;
          </p>

          <ul>
            <li>
              <a href="https://www.canva.com/create/media-kits/">Canva Templates</a>
            </li>
            <li>
              <a href="https://www.figma.com/templates/">Figma Design</a>
            </li>
          </ul>

          <h3>Podcast media kit samples</h3>
          <p>Take some inspiration from these digital media kits;</p>

          <p>
            <img src="/images/podcasting-media-kit.jpg" alt="Podcast Media Kit Examples" />
          </p>

          <p>
            <h4>Networks:</h4>

            <ul>
              <li>
                <a href="https://static1.squarespace.com/static/53ff2c53e4b0e1f6ca3c017d/t/5a340369e4966b79a0770f2c/1513358196242/Media+Kit+Q3+2017.pdf">
                  New York Public Radio
                </a>
              </li>
            </ul>
          </p>

          <p>
            <h4>Podcasts</h4>

            <ul>
              <li>
                <a href="https://ameliapodcast.com/press-kit/">AmeliaPodcast</a>
              </li>
              <li>
                <a href="https://podcastjunkies.com/media-kit/">Podcast Junkies</a>
              </li>
            </ul>
          </p>

          <p>
            Once you have made your kit make sure to share it with your contact, link to it from your website & send it
            to industry experts. And let us know, so we can add to our sample list!
          </p>

          <section>
            <br />
            <h2>Frequently Asked Questions</h2>
            <FAQ
              items={[
                {
                  question: 'Do I need a media kit for podcast sponsorship?',
                  answer:
                    'No, you can get sponsorship without a Media Kit. However, It is highly recommended to have a Media Kit to show the best side of your podcasts to potential supporters.',
                },
                {
                  question: 'Where do I put my press kit?',
                  answer:
                    'We recommend that you host your press kit on your Website, and use it as an early point of contact, to explain what your podcast is about. You can also include a link to your media kit in press releases and email signature.',
                },
                {
                  question: 'How often should I update my media kit?',
                  answer:
                    'You should aim to update the fact in your kit whenever you hit a new milestone or a minimum of one per quarter.',
                },
              ]}
            />
          </section>
        </article>
      </Layout>
    )
  }
}

export default Page
